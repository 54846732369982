.loader {
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #444444;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

#loader-overlay {
    position: fixed;
    /* Overlay takes up the entire screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    /* Semi-transparent background */
    z-index: 1000;
    /* Ensures the overlay is above other content */
    pointer-events: none;
    /* Disables mouse events on the overlay */
}

.loading-overlay {
    display: none;
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9998;
    align-items: center;
    justify-content: center;
}

.loading-overlay.is-active {
    display: flex;
}

/* 
#loader {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #E52A59;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
} */
.loading-pulse {
    background-color: #E52A59;
    padding: .8rem;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    position: relative;
}

.loading-pulse:after {
    content: '';
    position: absolute;
    background-color: #E52A59;
    opacity: 70%;
    width: 5rem;
    height: 5rem;
    z-index: -1;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 1s ease-out infinite;
}

.loading-pulse img {
    width: 100%;
}

@keyframes pulse {
    0% {
        opacity: 100%;
        width: 4.5rem;
        height: 4.5rem;
    }

    5% {
        opacity: 90%;
        width: 4.5rem;
        height: 4.5rem;
    }

    100% {
        opacity: 0;
        width: 8rem;
        height: 8rem;
    }
}