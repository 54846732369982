@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    height: 100vh;
}

body,
.app-root {
    height: 100%;
    width: 100%;
    margin: 0;
    /* display: flex;
    flex-direction: column; */
    font-family: 'Poppins' !important;
    /* overflow: hidden; */
}

#root {
    height: 100%;
}

.h-100 {
    height: 100% !important;
}

.chat-scrollBar {
    overflow-y: auto;
    overflow-x: hidden;
}

/* width */
.chat-scrollBar::-webkit-scrollbar {
    width: 2px;
}

/* Track */
.chat-scrollBar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

/* Handle */
.chat-scrollBar::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

/* Handle on hover */
.chat-scrollBar::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

.common-m {
    height: 100% !important;
    margin: 0 auto;
    padding: 2% 0%;
    /* display: flex;
    flex-direction: column; */
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
}

a {
    text-decoration: none !important;
}

a:hover {
    color: black !important;
}

.text-underline {
    text-decoration: underline !important;
}

.text-center {
    text-align: center !important;
}

.text-end {
    text-align: end !important;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.c-black-us {
    color: #000000;
}

.c-black {
    color: #2C3240;
}

.c-auth-frgt {
    color: #263145;
}

.c-white {
    color: #FFFFFF;
}

.c-theme-sub-header {
    color: #98A5B8 !important;
}

.c-theme {
    color: #E52A59 !important;
}

.c-header {
    color: #1B1A57;
}

.c-CB-sub-header {
    color: #4F5E7B;
}

.c-chat-time {
    color: #959EB0;
}

.bg-theme {
    background-color: #E52A59 !important;
}

.f-weight-7 {
    font-weight: 700;
}

.f-weight-6 {
    font-weight: 700;
}

.f-weight-5 {
    font-weight: 500;
}

.f-weight-4 {
    font-weight: 400;
}

.f-weight-3 {
    font-weight: 300;
}

.f-weight-2 {
    font-weight: 200;
}

.f-weight-1 {
    font-weight: 100;
}

.p-tb-2 {
    padding: 2% 0%;
}

.p-tb-3 {
    padding: 4% 0%;
}

.pl-1v {
    padding-left: 7px;
}

.pr-1v {
    padding-right: 7px;
}

.pl-1p {
    padding-left: 1.5%;
}

.pl-4p {
    padding-left: 4%;
}

.pl-1vw {
    padding-left: 1vw;
}

.pl-2vw {
    padding-left: 2vw;
}

.pb-1v {
    padding-bottom: 0.5vw;
}

.pb-7p {
    padding-bottom: 8%;
}

.pb-9p {
    padding-bottom: 9%;
}

.pt-2v {
    padding-top: 1.2vw;
}

.mt-1v {
    margin-top: 1vw !important;
}

.mt-2v {
    margin-top: 1.7vw !important;
}

.mt-40 {
    margin-top: 11vw;
}

.mt-6 {
    margin-top: 6vw;
}

.mt-1x {
    margin-top: 7px;
}

.ml-10 {
    margin-left: 10px;
}

/* .l-height-52 {
    line-height: 52px;
}

.l-height-30 {
    line-height: 25px;
}

.l-height-24 {
    line-height: 24px;
}

.l-height-21 {
    line-height: 21px;
} */
.f-12 {
    font-size: 0.69vw;
}

.f-13 {
    font-size: 0.79vw;
}

.f-14 {
    font-size: 0.83vw;
}

.f-16 {
    font-size: 0.92vw;
}

.f-18 {
    font-size: 1vw;
}

.f-20 {
    font-size: 1.15vw;
}

.f-24 {
    font-size: 1.35vw;
}

.f-34 {
    font-size: 1.97vw;
}

.f-36 {
    font-size: 2rem;
}

.f-50 {
    font-size: 2.8vw;
}

.b-radius {
    border-radius: 10px !important;
}


.d-flex-align {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
}

.d-flex-center-row {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.d-flex-center-column {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}

.d-flex-center {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.d-flex-c {
    display: flex !important;
    flex-direction: column;
}

.d-flex-end {
    display: flex !important;
    justify-content: end;
    align-items: center;
}

.d-flex-btw {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
}

.d-flex-align-end {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.d-flex-row {
    flex-direction: row;
}

.d-inline-flex {
    display: inline-flex;
}

.d-block {
    display: block;
}

.d-flex {
    display: flex !important;
}

.d-inline-block {
    display: inline-block !important;
}

.arrowButtons.MuiButtonBase-root {
    color: #200E32 !important;
    font-size: 0.125rem !important;
    font-weight: 100 !important;
}

.arrowButtons.MuiButton-root {
    min-width: 24px !important;
    width: 10px;
    height: 24px;
}

.onboarding-btn.MuiButton-root {
    color: #FFFFFF;
    font-size: 0.9vw;
}

.arrowButtons {
    background-color: #F3F6FC !important;
    border-radius: 50% !important;
    color: #200E32 !important;
}

.btn-none {
    padding: 0 !important;
    border: none !important;
    line-height: none !important;
    width: fit-content !important;
    font-weight: 600 !important;
}

.float-r {
    float: right;
    /* clear: both; */
}

.float-l {
    float: left;
    /* clear: both; */
}


.MuiButton-root {
    text-transform: capitalize !important;
}

/* .MuiButton-root:hover {
    background-color: transparent!important;
} */
.MuiButtonBase-root,
.MuiButton-root {
    min-width: fit-content !important;
}

.avartar-person.MuiAvatar-root {
    /* width: 100% !important;
    height: auto !important; */
    width: 2.7vw;
    height: 2.7vw;
}

/* img {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    object-fit: contain;
} */

.cust-width-10 {
    width: 15%;
    margin: 0 auto;
}

.cust-width-80 {
    width: 70%;
    margin: 0 auto;
}

.w-100 {
    width: 100%;
    margin: 0 auto;
}

/* .MuiAvatar-root {
    width: 3.7vw !important;
    height: 3.7vw !important;
} */

.avatar-text-align {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.avatar-mt,
.gap-btn-svg {
    margin-top: 10px !important;
}

.Online-indicator .MuiBadge-dot {
    background-color: #4CE417 !important;
    border: 2px solid #FFFFFF;
    width: 25%;
    height: 25%;
    border-radius: 50%;
}

/* .bg{
    background-color: red;
}
.bg-1{
    background-color:gold;
} */
.test-bg {
    height: auto;
    width: 100%;
}

.hh {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

#status-view {
    margin:0vw 2vw 1.2vw 2vw;
    position: relative;
    overflow: hidden;
}

.btn-disable {
    background-color: #00ff00;
}
.mb-4p{
    margin-bottom: 4px;
}
@media (min-width : 150px) and (max-width : 319px) {
    .md-center {
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column !important;
    }
}

@media (min-width : 320px) and (max-width : 480px) {
    .md-center {
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column !important;
    }

    .arrowButtons .MuiSvgIcon-root {
        width: 3vw;
        height: 3vw;
    }

    .arrowButtons.MuiButton-root {
        min-width: 17px;
        width: 10px;
        height: 17px;
    }

    .onboarding-indicatore .MuiMobileStepper-dot {
        width: 5px;
        height: 5px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .md-center {
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column !important;
    }

    .cust-width-10 {
        width: 25%;
        margin: 0 auto;
    }

    .cust-width-80 {
        width: 50%;
        margin: 0 auto;
    }

    .w-100 {
        width: 100%;
        margin: 0 auto;
    }
}

@media (min-width: 768px) and (max-width: 977px) {
    .md-center {
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column !important;
    }

    .cust-width-10 {
        width: 25%;
        margin: 0 auto;
    }

    .cust-width-80 {
        width: 50%;
        margin: 0 auto;
    }

    .w-100 {
        width: 100%;
        margin: 0 auto;
    }
}

@media (min-width : 978px) and (max-width : 1024px) {}

@media (min-width : 1025px) and (max-width: 1200px) {}

@media(min-width: 1201px) {}